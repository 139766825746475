.Image_Main{
    width: 93vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Image_Box{
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    width: 98%;
    height: 97%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}
.Image_Title{
    width: 100%;
    height: 10%;
    padding: 15px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.Image_Title_Txt{
    color: white;
    font-weight: 800;
    font-size: 20px;
    margin-right: 25px;
}
.Image_Separator{
    width: 100%;
    height: 2px;
    background: rgb(39,39,39);
    background: linear-gradient(90deg, rgba(39,39,39,1) 0%, rgba(128,128,128,1) 50%, rgba(39,39,39,1) 100%);
    border-radius: 1px;
}
.Image_List_Box{
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}

.Image_List{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: 15px;
}
.Image_Loading_And_Page{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.Image_PopUp_Main_Box{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 700px;
    background-color: rgb(0, 0, 0, 0.8);
    padding: 25px;
    color: white !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}
.Image_PopUp_Top_Bar{
    color: white !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.Image_PopUp_All_Txt{
    color: white !important;
    margin-top: 15px;
}
.Image_PopUp_CloseBtn{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Image_Up{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}
.Btn_Register{
    height: 35px;
    border: none;
    background-color: red;
    color: white;
    border-radius: 3px;
}
.Btn_Register:hover{
    transition: 3ms;
    background: rgb(207,32,48);
    background: linear-gradient(25deg, rgba(207,32,48,1) 0%, rgba(255,0,0,1) 100%);
    
}
.ImageRegister_Input{
    border: none;
    width: 93%;
    height: 40px;
    border-radius: 3px;
    border-bottom: red 2px solid;
    background-color: white;
}
.ImageRegister_Input:hover{
    border-bottom: red 3px solid;
}
.ImageRegister_label{
    color: white;
}
.ImageRegister_FBox{
    width: 100%;
    height: 100%;
}
.ImageRegister_Form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: center;
    justify-content: flex-start;
    padding: 25px;

}
.ImageRegister_Flexy{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    align-content: flex-start;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}
.ImageRegister_Input2{
    border: none;
    width: 93%;
    height: 40px;
    border-radius: 3px;
    border-bottom: red 2px solid;
}
.ImageRegister_Input2:hover{
    border-bottom: red 3px solid;
}
.Image_List_Box{
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.Image_List{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: 15px;
}
.Image_Loading_And_Page{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.ImgList_Main_Img_Box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.ImgList_Main_IMG{
    width: auto;
    max-height: 390px;
    user-select: none;
}
.ImgList_Main_left_BTN{
    margin-right: 25px;
    user-select: none;
}
.ImgList_Main_Right_BTN{
    margin-left: 25px;
    user-select: none;
}
.ImgList_Main_Btn{
    border: none;
    background: transparent;
    user-select: none;
}
.ImgList_Main_Img_section{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.ImgList_Main_Img_Dell_Btn{
    margin-top: 25px;
    width: 550px;
    border: none;
    height: 50px;
    color: white;
    background-color: red;
    user-select: none;
}