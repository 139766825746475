.container {
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
	display: flex;
	height: 30px;
	width: 30px;
	padding: 9px;
}

.icon {
	height: 23px;
	width: 23px;
}

.clickable:hover {
	background-color: var(--gray-scale-100);
}
