@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,300,500,600");

html, body {
	overflow-x: hidden !important;
	overflow-y:hidden !important;
}

input:focus {
	outline: none;
}

* {
	box-sizing: border-box;
}

:root {
	--primary-scale-900: rgb(88, 14, 20);
	--primary-scale-600: rgb(132, 21, 30);
	--primary-scale-800: rgb(176, 28, 40);
	--primary-scale-700: rgb(199, 31, 45);
	--primary-scale-500: rgb(221, 34, 50);
	--primary-scale-300: rgb(227, 79, 91);
	--primary-scale-200: rgb(234, 123, 132);
	--primary-scale-100: rgb(241, 167, 173);
	--primary-scale-50: rgb(252, 233, 234);

	--branding-main:rgb(176, 28, 40);
	--branding-main-dark: 	rgb(176, 28, 40);
	--branding-error: rgba(207, 12, 82, 1);
	--branding-tertiary: rgba(207, 12, 129, 1);
	--branding-warning: rgba(207, 129, 12, 1);
	--branding-success-scale500: rgba(12, 207, 125, 1);

	--forest-green: rgba(32, 180, 38, 0.2);

	--gray-scale-900: rgba(25, 25, 25, 1);
	--gray-scale-800: rgba(51, 51, 51, 1);
	--gray-scale-700: rgba(76, 76, 76, 1);
	--gray-scale-600: rgba(102, 102, 102, 1);
	--gray-scale-500: rgba(127, 127, 127, 1);
	--gray-scale-400: rgba(153, 153, 153, 1);
	--gray-scale-300: rgba(178, 178, 178, 1);
	--gray-scale-200: rgba(204, 204, 204, 1);
	--gray-scale-100: rgba(229, 229, 229, 1);
	--gray-scale-50: rgb(242, 243, 245);

	--gallery: rgba(237, 237, 237, 1);

	--white: rgba(255, 255, 255, 0.071);
	--white-2: rgba(255, 255, 255, 0.851);
	--gray-scalewhite: rgba(255, 255, 255, 1);

	--font-size-12px: 12px;
	--font-size-20px: 20px;
	--font-size-46px: 46px;
	--font-size-48px: 48px;
	--font-size-56px: 56px;
	--font-size-72px: 72px;

	--font-size-xxxs: 10px;
	--font-size-xxs: 11px;
	--font-size-s: 12px;
	--font-size-m: 14px;
	--font-size-l: 15px;
	--font-size-xl: 16px;
	--font-size-xxl: 19px;
	--font-size-xxxl: 32px;
	--font-size-xxxxl: 40px;
	--font-size-xxxxxl: 56px;

	--main-font: "Poppins";
	--main-font-light: "Poppins-Light";

	--max-width-desktop: 1500px;
	--side-padding-desktop: 60px;

	--breakpoint-tablet: 1080px;
	--breakpoint-mobile: 500px;
}

@media only screen and (max-width: 500px) {
	:root {
		--side-padding-desktop: 25px;
	}
}

@media only screen and (max-width: 1215px) {
	:root {
		--font-size-xxxl: 24px;
	}
}

p,
span,
h6,
h5,
h4,
h3,
h2,
input,
h1 {
	font-family: var(--main-font);
	color: var(--gray-scale-800);
}

p {
	line-height: 25px;
}

input {
	font-size: var(--font-size-m);
}

h1 {
	font-size: var(--font-size-56px);
	font-style: normal;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 0;
}

h2 {
	font-size: var(--font-size-xxxxl);
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0;
}

h3 {
	font-size: var(--font-size-xxxl);
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

h4 {
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

h5 {
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
h6 {
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

.clickable {
	cursor: pointer;
}

.hover-shadow-effect {
	cursor: pointer;
	transition: all 0.3s;
}

.hover-shadow-effect:hover {
	box-shadow: 0px 5px 15px rgba(28, 81, 130, 0.3);
	transform: scale(1.03);
	transition: all 0.3s;
}

@media only screen and (max-width: 1215px) {
	span,
	p {
		font-size: 15px;
	}
}

.nav-bar-offset {
	margin-top: 130px;
}

.heading-02--600 {
	font-family: var(--main-font);
	font-size: var(--font-size-46px);
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0;
}

.heading-03--600 {
	font-family: var(--main-font);
	font-size: var(--font-size-xxxxl);
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0;
}

.heading-05--400 {
	font-family: var(--main-font);
	font-size: var(--font-size-xxxl);
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0;
}

.heading-05--600 {
	font-family: var(--main-font);
	font-size: var(--font-size-xxxl);
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0;
}

.body-medium-600 {
	font-family: var(--main-font);
	font-size: var(--font-size-xl);
	font-style: normal;
	font-weight: 600;
	letter-spacing: 0;
}

.body-medium-400 {
	font-family: var(--main-font);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0;
}

.body-small-500 {
	font-family: var(--main-font);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0;
}

.body-tiny-400 {
	font-family: var(--main-font);
	font-size: var(--font-size-12px);
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0;
}

.poppins-medium-curious-blue-32px {
	color: var(--primary-scale-500);
	font-family: var(--main-font);
	font-size: var(--font-size-xxxl);
	font-style: normal;
	font-weight: 500;
}

.poppins-normal-gray-15px {
	color: var(--gray-scale-500);
	font-family: var(--main-font);
	font-size: var(--font-size-l);
	font-style: normal;
	font-weight: 400;
}

.poppins-medium-white-10px {
	color: var(--gray-scalewhite);
	font-family: var(--main-font);
	font-size: var(--font-size-xxxs);
	font-style: normal;
	font-weight: 500;
}

.poppins-normal-gravel-11px {
	color: var(--gray-scale-700);
	font-family: var(--main-font);
	font-size: var(--font-size-xxs);
	font-style: normal;
	font-weight: 400;
}

.poppins-medium-gray-14px {
	color: var(--gray-scale-500);
	font-family: var(--main-font);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 500;
}

.poppins-medium-eerie-black-14px {
	color: var(--gray-scale-900);
	font-family: var(--main-font);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: 500;
}

.border-1px-gray-scale--100 {
	border: 1px solid var(--gray-scale-100);
}

.border-1px-gray-scalewhite {
	border: 1px solid var(--gray-scalewhite);
}
