.Client_Background{
    width: 100vw;
    height: 100vh;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-image: url(./../../static/img/SuperGraphic_Primary_RGB.png);
    background-size: 50%;
    background-position-x: right;
    background-position-y: bottom;
}.Client_Background_IMG{
    width: 100%;
    height: 100%;
   
    background-image: url(./../../static/img/SuperGraphic_Secondary_RGB.png);
    background-size: 25%;
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;
}.Client_Background_IMG_BNI{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-image: url(./../../static/logo/logo-wsf-agence.svg);
    background-size: 5%;
    background-position-x: 99%;
    background-position-y: 1%;
    background-repeat: no-repeat;
}.Client_Background_IMG_WSF{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-image: url(./../../static/logo/BNI_logo_Red_RGB.png);
    background-size: 10%;
    background-position-x: 1%;
    background-position-y: 99%;

    background-repeat: no-repeat;
}.Client_Main_Box{
    width: 50%;
    height: 80%;
    background-color: rgb(255, 255, 255, 0.6);
    border: var(--branding-main) 2px solid;
    border-radius: 5px;
    padding: 25px;
    display: flex;
}.Client_Main_Box_InsideBox{
    width: 100%;
    height: 100%;
}.Client_Main_Box_Top_Select{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    
}.select{
    width: 40%;
    padding: 10px;
}.CLient_Main_Box_Select{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}.CLient_Main_Box_Mid_Explication{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 25px;
}.CLient_Main_Box_Mid_P{
    text-align: center;
}.CLient_Main_Box_Jobs_Selection{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    height: 60%;
}.Client_Main_Box_All_Select_Box{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}.Btn_Box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Client_Day_Count{
    background-color: #cf2030;
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Client_Day_Count_Days{
    color: white;
    font-weight: 600;
     font-size: 60px;
    height: 25%;    
}
.Client_Day_Count_Text{
    color: white;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    height: 20%;
    line-height: 40px;
}
.Client_Day_Count_back_bar{
    background-color: #b4b1b1;
    width: 50%;
    height: 25px;
    border-radius: 55px;
    z-index: 1;
}.Client_Day_Count_Anim{
    background: rgb(255,255,255);
    background: linear-gradient(100deg, rgb(192, 190, 190) 0%, rgba(180,177,177,1) 100%);
    animation: wave 2s linear infinite;
    width: 5%;
    height: 25px;
    position: absolute;
    z-index: -1;
    border-radius: 100px;
}
.Copyright{
    text-align: center;
    margin-top: 25px;
    font-size: 12px;
}
.Copyright2{
display: none;
}
.Mobile_Footer{
    display: none;
}


@keyframes wave {
    0% {
        transform: translateX(-0%);
      }
      100% {
        transform: translateX(800%);
      }
}


@media only screen and (max-width: 425px) {
	.Client_Day_Count_back_bar {
        background-color: #b4b1b1;
        width: 95%;
        height: 25px;
        border-radius: 55px;
        z-index: 1;
    }
    .Client_Day_Count_Text{
        color: white;
        font-weight: 600;
        font-size: 30px;
        text-align: center;
        height: 35%;
        line-height: 40px;
    }
    .Client_Day_Count_Days{
        color: white;
        font-weight: 600;
        font-size: 40px;
        height: 25%;
    }
    .Client_Day_Count{
        padding: 5px;
    }
    @keyframes wave {
        0% {
            transform: translateX(-0%);
          }
          100% {
            transform: translateX(1800%);
          }
    }
    .Client_Background{
        width: 100vw;
        height: 100vh;
        background-color: white;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background-image: url(./../../static/img/SuperGraphic_Primary_RGB.png);
        background-size: 50%;
        background-position-x: right;
        background-position-y: bottom;
    }.Client_Background_IMG{
        width: 100%;
        height: 100%;
       
        background-image: url(./../../static/img/SuperGraphic_Secondary_RGB.png);
        background-size: 25%;
        background-position-x: left;
        background-position-y: top;
        background-repeat: no-repeat;
    }.Client_Background_IMG_BNI{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background-image: url(./../../static/logo/logo-wsf-agence.svg);
        background-size: 15%;
        background-position-x: 99%;
        background-position-y: 1%;
        background-repeat: no-repeat;
    }.Client_Background_IMG_WSF{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background-image: url(./../../static/logo/BNI_logo_Red_RGB.png);
        background-size: 20%;
        background-position-x: 1%;
        background-position-y: 99%;
    
        background-repeat: no-repeat;
    }.Client_Main_Box{
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255, 0.6);
        border: var(--branding-main) 0px solid;
        border-radius: 0px;
        padding: 5px;
        display: flex;
        overflow: scroll;
    }.Client_Main_Box_InsideBox{
        width: 100%;
        height: 100%;
    }.Client_Main_Box_Top_Select{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        
    }.select{
        width: 60%;
        padding: 3px;
        margin-top: 28px;
    }.CLient_Main_Box_Select{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        margin-top: 20px;
        height: auto;
    }.CLient_Main_Box_Mid_Explication{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin-top: 27px;
        height: 25%;
    }.align_txt{
        text-align: center;
        margin-bottom: 25px;
    }
    .CLient_Main_Box_Mid_P{
        text-align: center;
    }.CLient_Main_Box_Jobs_Selection{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 0px;
        height: auto;
    }.Client_Main_Box_All_Select_Box{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 51px;
    }.Btn_Box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        /* margin-bottom: 25px !important; */
        margin-top: 25px !important;
    }
    .Copyright{
        display: none;
    }
    .Copyright2{
        text-align: center;
        margin-top: 5px;
        display: block;
    }
    .Mobile_Footer{
        display: block;
        background-color: white;
        border-top: #cf2030 2px solid;
        padding: 5px;
    }
} 
