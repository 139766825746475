.Back_Box{
    width: 17vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        
        
}
.Main_Box{
    width: 15vw;
    height: 97vh;
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
}
.Main_Box::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.Up_Nav{
    width: 100%;
    height: 10%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Nav_Txt{
    color: white;
    font-weight: 600;
}
.NavSeparator{
    width: 80%;
    height: 2px;
    background: rgb(39,39,39);
    background: linear-gradient(90deg, rgba(39,39,39,1) 0%, rgba(128,128,128,1) 50%, rgba(39,39,39,1) 100%);
    border-radius: 1px;
}