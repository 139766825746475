.LogBox_Bg{
    background: linear-gradient(to top left,#fff 50%, #b01c28 50.1%) top left/100px 100px no-repeat,white; 
    width: 60vw;
    height: 60vh;
    border-radius: 3px;
    padding-top: 120px !important;
    display: flex;
    padding: 25px;
    display: flex;
}
.LogBox_leftSide{
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.LogBox_RightSide{
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
}
/* .LogBox_leftSide_LogoBox{

} */
.LogBox_leftSide_Title{
    font-weight: 500;
    font-size: var(--font-size-20px);
    margin-bottom: 15px;
    margin-top: 15px;
}
.LogBox_leftSide_TxtBox{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    margin-top: 25px;
}
.LogBox_leftSide_Txt{
    font-size: var(--font-size-12px);
}
.LogBox_leftSide_Txt_Small{
    margin-top: 40px;
    font-size: var(--font-size-xxxs);

}
.LogBox_WSF_Logo{
    width: 75px;
    height: 75px;
}
.LogBox_BNI_Logo{
    margin-left: 50px;
    width: 195px;
    height: 75px;
}
.LogBox_RightSide_InputBox{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;  
}
label {
    order: -1;
    padding-left: 5px;
    transition: all 0.3s ease-in;
    transform: translateY(27px);
    pointer-events: none;
    color: grey;
  }
  input:focus + label {
    transform: translateY(-2px);
    color: red;
    font-size: 15px;
  }
  input:not(:placeholder-shown)  + label {
    transform: translateY(-2px);
    color: red;
    font-size: 15px;
  }
  .LogBox_RightSide_Input{
      border-radius: 3px;
      width: 100%;
      height: 40px;
      border: none;
      border-bottom: rgb(220, 0, 0) 3px solid;
      box-shadow: rgb(0 0 0 / 30%) 0px 1px 3px inset, rgb(255 255 255) 0px 0px 0px, rgb(255 255 255) 0px 0px 0px;   
  }
  .LogBox_RightSide_Label{
      font-size: 17px;
  }
  .LogBox_RightSide_Input:hover{
    border-bottom: rgb(0, 0, 0) 2px solid;
}
.LogBox_RightSide_BTN{
    height: 35px;
    width: 150px;
    border: none;
    background: #b01c28;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
}
.LogBox_RightSide_BTN:hover{
    background: white;
    color: black;
    border: #b01c28 1px solid;
}
.LogBox_RightSide_Title{
    font-size: var(--font-size-20px);
    font-weight: 800;
}

@media only screen and (max-width: 425px){
    .LogBox_Bg{
        width: 100vw;
        height: 100vh;
        border-radius: 3px;
        padding-top: 120px !important;
        padding: 25px;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }
    .LogBox_leftSide{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
    }
    .LogBox_leftSide_LogoBox{
        display: flex;
    }
    .LogBox_RightSide{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
       }
    .LogBox_leftSide_Title{
        font-weight: 500;
        font-size: var(--font-size-20px);
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .LogBox_leftSide_TxtBox{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: center;
        margin-top: 25px;
    }
    .LogBox_leftSide_Txt{
        font-size: var(--font-size-12px);
    }
    .LogBox_leftSide_Txt_Small{
        margin-top: 40px;
        font-size: var(--font-size-xxxs);
    
    }
    .LogBox_WSF_Logo{
        width: 75px;
        height: 75px;
    }
    .LogBox_BNI_Logo{
        margin-left: 50px;
        width: 195px;
        height: 75px;
    }
    .LogBox_RightSide_InputBox{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;  
    }
    label {
        order: -1;
        padding-left: 5px;
        transition: all 0.3s ease-in;
        transform: translateY(27px);
        pointer-events: none;
        color: grey;
      }
      input:focus + label {
        transform: translateY(-2px);
        color: red;
        font-size: 15px;
      }
      input:not(:placeholder-shown)  + label {
        transform: translateY(-2px);
        color: red;
        font-size: 15px;
      }
      .LogBox_RightSide_Input{
          border-radius: 3px;
          width: 100%;
          height: 40px;
          border: none;
          border-bottom: rgb(220, 0, 0) 3px solid;
          box-shadow: rgb(0 0 0 / 30%) 0px 1px 3px inset, rgb(255 255 255) 0px 0px 0px, rgb(255 255 255) 0px 0px 0px;   
      }
      .LogBox_RightSide_Label{
          font-size: 17px;
      }
      .LogBox_RightSide_Input:hover{
        border-bottom: rgb(0, 0, 0) 2px solid;
    }
    .LogBox_RightSide_BTN{
        height: 35px;
        width: 150px;
        border: none;
        background: #b01c28;
        color: white;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: 500;
    }
    .LogBox_RightSide_BTN:hover{
        background: white;
        color: black;
        border: #b01c28 1px solid;
    }
    .LogBox_RightSide_Title{
        font-size: var(--font-size-20px);
        font-weight: 800;
    }
}