.LogBox_RightSide_BTN{
    height: 35px;
    width: 180px;
    border: none;
    background: #b01c28;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
}
.LogBox_RightSide_BTN:hover{
    background: white;
    color: black;
    border: #b01c28 1px solid;
}

@media only screen and (max-width: 425px) {
    .LogBox_RightSide_BTN{
        margin-bottom: 25px;
    }
}