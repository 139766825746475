.Message_Box_Main{
    padding: 15px;
    background-color: white;
    border: var(--branding-main) 1px solid;
    display: flex;
    width: 25vw;
    height: 45vh;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: stretch;
}
.Message_Box_Header_And_Text{
    padding: 25px;        
    height: 70%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
    justify-content: space-evenly;
}
.Message_Box_button{
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.Message_Box_Btn{
    width: 50%;
}

@media only screen and (max-width: 425px) {
    .Message_Box_Main{
        padding: 15px;
        background-color: white;
        border: var(--branding-main) 1px solid;
        display: flex;
        width: 345px;
        height: 345px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: stretch;
    }
    .Message_Box_Header_And_Text{
        padding: 25px;        
        height: 70%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        align-items: center;
        justify-content: space-evenly;
    }
    .Message_Box_button{
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }
    .Message_Box_Btn{
        width: 50%;
    }
}