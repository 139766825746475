.heading {
	display: flex;
	flex-direction: column;
	max-width: var(--max-width-desktop);
	padding: 0 var(--side-padding-desktop);
	align-items: center;
	margin-bottom: 50px;
	text-align: center;
}

.line {
	width: 75px;
	margin-top: 30px;
}
