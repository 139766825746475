.OverView_Box_Bg_Color{
    background: linear-gradient(135deg, rgb(171, 220, 255) 0%, rgb(3, 150, 255) 100%);
}
.OverView_Box_Bg_Color2{
    background: linear-gradient(70deg, rgba(237,255,171,1) 0%, rgba(201,255,3,1) 100%);
}
.OverView_Box_Bg_Color3{
    background: linear-gradient(100deg, rgba(176,171,255,1) 0%, rgba(4,3,255,1) 100%);
}
.OverView_Box_Bg_Color4{
    background: linear-gradient(30deg, rgba(218,171,255,1) 0%, rgba(170,3,255,1) 100%);
}
.OverView_Box_Bg_Color5{
    background: linear-gradient(-16deg, rgba(171,255,171,1) 0%, rgba(3,255,17,1) 100%);
}
.OverView_Box_Bg_Color6{
    background: linear-gradient(16deg, rgba(255,221,171,1) 0%, rgba(255,144,3,1) 100%);
}
.OverView_Box_Recap{
    width: 335px;
    height: 170px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px;
}
.OverView_Box_Recap_Inside{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.OverView_Box_Recap_Flex{
    display: flex;
    width: 60%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 25px;
}
.OverView_Box_Recap_Icon{
    width: 60px;
    height: 60px;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}


.Image_Main{
    width: 93vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Image_Box{
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
    width: 98%;
    height: 97%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}
.Image_Title{
    width: 100%;
    height: 10%;
    padding: 15px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.Image_Title_Txt{
    color: white;
    font-weight: 800;
    font-size: 20px;
    margin-right: 25px;
}
.Image_Separator{
    width: 100%;
    height: 2px;
    background: rgb(39,39,39);
    background: linear-gradient(90deg, rgba(39,39,39,1) 0%, rgba(128,128,128,1) 50%, rgba(39,39,39,1) 100%);
    border-radius: 1px;
}
.Image_List_Box{
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}

.Image_List{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: 15px;
}
.Image_Loading_And_Page{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.Image_Up{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}
.ImageRegister_Input{
    border: none;
    width: 93%;
    height: 40px;
    border-radius: 3px;
    border-bottom: red 2px solid;
    background-color: white;
}
.ImageRegister_Input:hover{
    border-bottom: red 3px solid;
}

.Image_List_Box{
    width: 100%;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.Image_List{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: 15px;
}
.Image_Loading_And_Page{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.show_config{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    width: 100%;
    justify-content: space-around;
}
.all_config{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: flex-start;
}
.Flex_Center{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 25px;
}
.label{
    color: white;
}

.input_and_label_boc{
    width: 100%;
    margin-top: 25px;
}
.Accordion{
    background-color: transparent !important;
    border: none !important;
    width: 100%;
    box-shadow: none !important;
    position: static !important;
}