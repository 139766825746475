.container {
	display: flex;
	position: relative;
}

.pages_container {
	display: flex;
}

.arrow_right {
	background-color: var(--gray-scale-50);
	margin-left: 15px;
}

.arrow_left {
	transform: rotate(180deg);
	background-color: var(--gray-scale-50);
	margin-right: 15px;
}

.arrow_symbol {
	filter: brightness(65%);
	width: 10px !important;
	height: 15px;
}

.page {
	border-radius: 130px;
	display: flex;
	height: 36px;
	width: 36px;
}

.page_active {
	background-color: var(--branding-main);
}

.page_number {
	color: var(--gray-scale-600);
	height: 20px;
	line-height: 24px;
	margin-left: 8px;
	margin-top: 8px;
	cursor: pointer;
	text-align: center;
	width: 20px;
}

.page_active .page_number {
	color: var(--gray-scalewhite);
	font-weight: 500;
}
