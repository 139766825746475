.container {
	align-items: center;
	background-color: var(--branding-main-dark);
	border-radius: 16px;
	display: flex;
	height: 20px;
	justify-content: center;
	left: 14px;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 20px;
}

.text {
	height: 10px;
	letter-spacing: 0;
	text-align: center;
}
