.Background{
    display: flex;
    flex-grow: 1;
    margin: 0px auto;
    flex-direction: column;
    background-size: 150%;
    background-position: -30px 240px;
    min-height: 100vh;
    background-color: rgb(200, 201, 199);
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}